import React, { createContext, useState, useEffect } from 'react';

const themes = {
  light: {
    name: 'light',
    background: 'bg-dark-teal-100',
    backgroundLight: 'bg-white',
    backgroundExtraLight: 'bg-dark-teal-50',
    text: 'text-gray-700',
    textLight: 'text-gray-400',
    textExtraLight: 'text-gray-500',
    textStrong: 'text-gray-900',
    fillTheme: '#30B585',
    fillBrand: '#58595B',
  },
  dark: {
    name: 'dark',
    background: 'bg-gray-800',
    backgroundLight: 'bg-gray-700',
    backgroundExtraLight: 'bg-gray-500',
    text: 'text-dark-teal-100',
    textLight: 'text-gray-300',
    textExtraLight: 'text-gray-200',
    textStrong: 'text-gray-100',
    fillTheme: '#30B585',
    fillBrand: '#ECECEC',
  },
};

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState('light');

  useEffect(() => {
    const loadTheme = () => {
      const localStorageTheme = localStorage.getItem('theme');
      return localStorageTheme || 'light';
    };
    setCurrentTheme(loadTheme());
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', currentTheme);
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={[themes[currentTheme], newTheme => themes[newTheme]]}>
      <div>{children}</div>
    </ThemeContext.Provider>
  );
};

const translate = text => {
  if (text.toLowerCase() === 'advisories') {
    return 'avisos';
  }
  return text;
};

export { ThemeContext, ThemeProvider, themes, translate };
